<template>
	<div class="post-article">
		<h1 v-if="article.title" class="text-ch-red mb-0" v-html="article.title"></h1>
		<h2 v-if="article.subtitle" class="!text-lg text-gray-700 mt-0.5" v-html="article.subtitle"></h2>

		<div v-if="objects !== null && objects.children.length > 0" class="grid grid-cols-1 gap-8 lg:grid-cols-3 sm:grid-cols-2 mt-6">
			<template v-for="child of objects.children" :key="child.slug">
				<a v-if="child.meta?.external_link" target="_blank" :href="child.meta.external_link">
					<img class="transition-all rounded-none hover:scale-105 graphical-abstract !w-auto max-h-[340px]" :src="child?.meta?.img" alt="" />
					<p class="mt-1 font-normal text-ch-red">{{ child?.name }}</p>
          <p v-if="child?.meta?.subtitle">{{ child?.meta?.subtitle }}</p>
				</a>
				<RouterLink v-else :to="`/archive/${objects.slug}/${child.slug}`">
          <img class="transition-all rounded-none hover:scale-105 graphical-abstract !w-auto max-h-[340px]" :src="child?.meta?.img" alt="" />
					<p class="mt-1 font-normal text-ch-red">{{ child?.name }}</p>
          <p v-if="child?.meta?.subtitle">{{ child?.meta?.subtitle }}</p>
				</RouterLink>
			</template>
		</div>

		<!-- <div class="p-8 prose-base" v-html="article.content"></div> -->
		
		<!-- <div class="relative flex" style="min-height: 460px">
			<img @mouseover="hover" v-for="meta in article.meta" :key="meta.date" :src="meta.img" class="absolute max-w-xs transition-all cursor-pointer">
		</div> -->
	</div>
</template>

<script>
export default {
	props: ['article'],
	inject: ['api'],
	data() {
		return {
			objects: null,
			notFound: false
		}
	},
	created() {
		const issueSlug = this.$route?.params?.pathMatch?.[1] || ''

		this.api({
			url: `/issue/${issueSlug}`,
			callback: data => {
				this.objects = data
			},
			errorCallback: () => {
				this.notFound = true
			}
		})
	},
	methods: {
		hover(e) {
			/* TODO console.log(e) */
		}
	}
}
</script>

<style scoped>
/* img {
	box-shadow: 10px 0 5px -2px rgba(0,0,0,.2);
}
img:nth-child(1) {
	z-index: 6;
}
img:nth-child(2) {
	left: 10%;
	z-index: 5;
}
img:nth-child(3) {
	left: 20%;
	z-index: 4;
}
img:nth-child(4) {
	left: 30%;
	z-index: 3;
}
img:nth-child(5) {
	left: 40%;
	z-index: 2;
}
img:nth-child(6) {
	left: 50%;
	z-index: 1;
} */
</style>
