<template>
	<div class="flex items-center justify-between h-16 py-2 mx-6 text-base font-medium text-white md:h-14 md:mx-0">
		<div class="flex items-center">
			<img src="../../../assets/img/ch_header_logo_white.png" class="mr-2" style="height: 2.15rem" alt="Cardiologica Hungarica logo">
			<p class="text-sm font-normal leading-4">Cardiologica<br>Hungarica</p>
		</div>
		<div class="relative py-2 mr-1 cursor-pointer last:mr-0">
			<a class="hover:bg-white hover:text-gray-600 transition-all p-3 rounded-md grid place-items-center py-2 px-2.5"
				@click="toggleMenu(true)">
				<font-awesome-icon icon="fa-solid fa-bars" class="text-2xl" />
			</a>
		</div>
	</div>
	<TransitionRoot :show="menuState" as="template" :appear="true">
		<Dialog as="div" class="fixed z-50 overflow-x-auto lg:hidden font-utile" @close="toggleMenu(false)">
			<div class="fixed inset-0 z-50" />
			
			<TransitionChild
					as="template"
					enter="duration-150 transition transform"
					enter-from="translate-x-full"
					enter-to="translate-x-0"
					leave="duration-150"
					leave-from="translate-x-0"
					leave-to="translate-x-full"
			>
				<DialogPanel
					class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-[20rem] sm:ring-1 sm:ring-gray-900/10">
					<div class="flex items-center justify-between pt-2">
						<a class="-mt-4 text-2xl font-adobe-caslon-pro text-ch-red">
							Cardiologia Hungarica
						</a>
						<button type="button" class="-mr-2.5 -mt-3.5 rounded-md p-2.5 text-gray-700" @click="toggleMenu(false)">
							<span class="sr-only">Close menu</span>
							<XMarkIcon class="w-6 h-6" aria-hidden="true" />
						</button>
					</div>
					<div class="flow-root mt-6">
						<div class="-my-6 divide-y divide-gray-500/10">
							<ul class="py-6 space-y-2">
								<template v-for="nav of props.navigation" :key="nav.name">
									<MobileMenuItem @close="toggleMenu(false)" @click="handleClick" :nav="nav" />

									<div v-if="nav?.children?.length > 0 && dropdownState(nav?.href)" class="pl-2">
										<template v-for="children of nav.children" :key="children.name">
											<MobileMenuItem @close="toggleMenu(false)" @click="handleClick" :nav="children" />
											
											<div v-if="children?.children?.length > 0 && dropdownState(children?.href)" class="pl-2">
												<MobileMenuItem @close="toggleMenu(false)" v-for="child of children.children" :key="child.name" :nav="child" />
											</div>
										</template>
									</div>
								</template>

								<hr>
								
								<router-link
                  :to="{ name: 'search' }"
									class="flex items-center w-full px-3 py-2 -mx-3 text-base font-semibold leading-7 text-gray-900 rounded-lg cursor-pointer hover:bg-gray-50"
                  @click="toggleMenu(false)"
                >
                  Search
                </router-link>
							</ul>
						</div>
					</div>
				</DialogPanel>
			</TransitionChild>
		</Dialog>
	</TransitionRoot>
</template>

<script setup>
import { Dialog, DialogPanel, TransitionRoot, TransitionChild } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import MobileMenuItem from './MobileMenuItem.vue'
import { ref } from 'vue'

const props = defineProps({
	navigation: {
		type: Array,
		required: true
	},
})

const toggleMenu = (state) => {
	menuState.value = state
}

const menuState = ref(false)

const dropdown = ref(flattenObject(props.navigation))
const toggleDropdown = (href, state) => {
	dropdown.value[href] = state
}
const dropdownState = (href) => {
	return dropdown.value[href]
}

const handleClick = (nav) => {
	if (nav?.children?.length > 0) {
		console.log(nav.href)
		toggleDropdown(nav.href, !dropdown.value?.[nav.href] ?? true)
	}
}

function flattenObject(obj, parentKey = '', result = {}) {
	for (let key in obj) {
		let newKey = parentKey ? `${parentKey}.${key}` : key;
		
		if (typeof obj[key] === 'object' && obj[key] !== null) {
			flattenObject(obj[key], newKey, result);
		} else {
			if (newKey.includes('href')) {
				result[obj[key]] = false;
			}
		}
	}
	return result;
	}
</script>

<style>
.menu-item {
	@apply flex items-center px-3 py-2 -mx-3 text-base font-semibold leading-7 text-gray-900 rounded-lg cursor-pointer hover:bg-gray-50;
}
</style>
